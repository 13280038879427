<template>
  <div>
    <table class="w-full table-auto">
      <thead>
      <tr>
        <th class="text-left text-gray-400 ">Producto</th>
        <th class="text-left text-gray-400 ">Categoría</th>
        <th class="text-right text-gray-400 ">Existencias</th>
        <th class="text-right text-gray-400 " v-if="$store.state.account.canEditOrdersPrices">Costo</th>
        <th class="text-right text-gray-400 ">Precio</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="product in products"
        :key="product.id"
        @click="addProduct(product)"
        :class="product.quantity <= 0 ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer hover:bg-gray-50'">
        <td class="text-left">
          <div class="flex flex-row items-start space-x-2">
              <span>
                <i :class="[ isProductSelected(product)
                  ? 'bx bx-check-square text-xl text-purple-500'
                  : 'bx bx-checkbox text-xl text-gray-300' ]"
                />
              </span>
            <div>
              <span class="block font-bold">{{ product.name }}</span>
              <span class="block text-xs">{{ product.description }}</span>
            </div>
          </div>
        </td>
        <td class="text-left">
          <span>{{ product.category }}</span>
        </td>
        <td class="text-right">
          <span>{{ product.quantity }}</span>
        </td>
        <td class="text-right" v-if="$store.state.account.canEditOrdersPrices">
          <span>$ {{ product.cost }}</span>
        </td>
        <td class="text-right">
          <span>$ {{ product.price }}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'ProductsSearchList',
  props: {
    products: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectedProducts: [],
    };
  },
  methods: {
    isProductSelected(product) {
      return this.selectedProducts.some((s) => s.id === product.id);
    },
    addProduct(product) {
      if (product.quantity <= 0) return;
      const indexOfCurrentProduct = this.selectedProducts.findIndex((s) => s.id === product.id);
      if(indexOfCurrentProduct === -1) {
        product.productId = product.id;
        this.selectedProducts.push(product);
      }
      else
        this.selectedProducts.splice(indexOfCurrentProduct, 1);
      this.$emit('onChangeSelectedProducts', this.selectedProducts);
    },
  },
};
</script>
